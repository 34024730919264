.footer-sec-2 {
    
}
.footer-sec-2 .location-outer {
    position: relative;
}

.addreess-section {
    margin-top: -130px;
    margin-bottom: 65px;
    position: relative;
    overflow: hidden;
    z-index: 9999;
    background: #fff;}


    .footer-sec-2  .addreess-section ul li {
        padding: 60px 20px 60px 40px;
    }

    .footer-sec-2 .addreess-section h4 {
        display: inline-block;
        position: relative;
        font-size: 20px;
        color: #00aeef;
        padding-bottom: 10px;
        margin-bottom: 15px;
        font-family: "Montserrat", sans-serif;
    }

    .footer-sec-2 .addreess-section h4:before {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 30px;
        height: 1px;
        background: #000;
        content: "";
    }
    .footer-sec-2 .addreess-section p {
        line-height: 24px;
        margin-bottom: 18px;
        color: #6f7a82;
        font-size: 14px;
    }
    .footer-sec-2   .addreess-section .map {
        font-size: 14px;
        color: #bdbdbd;
    }

    .footer-links h4 {
        font-size: 15px;
        color: #536470;
        margin-bottom: 15px;
        font-weight: 600;
    }
    .footer-sec-2 ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }
    .footer-sec-2 .list-f02 li a {
        font-size: 14px;
        color: #5e707d;
        line-height: 32px;
        position: relative;
        text-decoration: none;
        
    }

    .footer-sec-2 .list-f03 li a{
   text-decoration: none;
   font-size: 20px;
   color: #414e58;
   display: block;
   padding: 20px 0px;

    }

    .footer-sec-2 .list-f03 li i {
        font-size: 20px;
        width: 35px;
        height: 35px;
       
        border: 2px solid #00aeef;
        text-align: center;
        border-radius: 50%;
        line-height: 35px;
        
        left: 0;
        top: -3px;
        color: #00aeef;
       
        
        
    }

    .footer-sec-2 .newsletter-box .txt-box {
        width: calc(100% - 133px);
        border: 1px solid #d5d5d5;
        height: 44px;
        padding: 0 10px;
        font-size: 13px;
        color: #6f7a82;
        float: left;
    }

    .footer-sec-2 .subscribe-btn {
        height: 44px;
        width: 133px;
        border: none;
        background: #00aeef;
        font-size: 14px;
        color: #fff;
        float: right;
        text-align: center;
        line-height: 44px;
    }

    .footer-sec-2 .newsletter-box p {
        font-size: 13px;
        margin-top: 8px;
        clear: both;
        display: block;
        padding-top: 8px;
    }

    .footer-sec-2 .f-award {
        font-size: 22px;
        color: #6f7a82;
        padding: 15px;
        background: #fff;
        margin-bottom: 8px;
        -webkit-transition: all 0.3s ease-out 0s;
        transition: all 0.3s ease-out 0s;
        display: block;
        text-decoration: none;
    }
    .footer-sec-2 .f-award span {
        display: block;
        background: url(https://cdn.acodez.in/wp-content/themes/acodez-theme/images/icon_sprite.png) no-repeat 0 0;
        padding-left: 40px;
        min-height: 35px;
    }

    @media(max-width:768px){
        .footer-sec-2 .swiper-slide {
            width: 100% !important;
            transform: translateZ(0);
            backface-visibility: hidden;
        }
    }
    .list-f02 li a:hover{
  color: #00aeef;
    }
    /* .footer-sec-2 .swiper-slide{
        width: 250px !important;
        position: relative;
    } */