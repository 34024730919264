.about-page .button-1 {
    color: #00aeef !important;
    }
    .about-page .header .ul-1 ul li a {
        color: #5e707d !important;
    }
    
    .about-page .caption {
        position: absolute;
        left: 0;
        width: 100%;
        top: 25%;
        text-align: left;
        z-index: 999;
    }
    .about-page .nav-bar-icon i {
        color: #5e707d !important;
    }




    .about-page  h3 {
        font-size: 47px;
        text-align: center;
    color: #6f7a82;
    margin-bottom: 15px;
    font-weight: 100;
    }
    .about-page  p{
        font-size: 15px;
        line-height: 30px;
        color: #6f7a82;
    
    margin-bottom: 15px;
text-align: center;
    }

    .credentials {
        padding: 40px 0;
        display: flex;
        flex-wrap: wrap;
    justify-content: center;
    }

    .credentials .box {
        margin-bottom: 40px;
    }

    .credentials .outer {
        padding: 30px 20px;
    }
    .credentials .outer:hover {
        background: #fff;
        -webkit-box-shadow: 0px 30px 62px 0px rgba(0, 0, 0, 0.15);
        box-shadow: 0px 30px 62px 0px rgba(0, 0, 0, 0.15);
    }
    .inner-sec1 {
        text-align: center;}


        .credentials .icon {
            width: 80px;
            height: 60px;
            margin: 0 auto 10px auto;
            background: url(https://cdn.acodez.in/wp-content/themes/acodez-theme/images/icon_sprite.png) no-repeat 13px -52px;
        }

        .credentials .box p {
            height: 100px;
        }

        .credentials .box p {
            max-width: 256px;
            margin: 0 auto;
        }

        .credentials .i-2 {
            background-position: 3px -120px;
        }
        .credentials .i-3 {
            background-position: 8px -204px;
        }
        .credentials .i-4 {
            background-position: 5px -283px;
        }
        .credentials .i-5 {
            background-position: 8px -360px;
        }
        .credentials .i-6 {
            background-position: 7px -443px;
        }
@media(max-width:768px){
    .about-page .caption {
        position: relative;
       
        left: 0;
        width: 100%;
        top: 25%;
        text-align: left;
        z-index: 999;
    }

    .about-page .img {
        height: 300px;
        background-size: cover !important;
        background: url(https://cdn.acodez.in/wp-content/themes/acodez-theme/images/works-banner.jpg) no-repeat left top;
    
    }

}
   