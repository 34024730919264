.home-blog {
    padding: 120px 0;
    background: #f6f6f6;
    position: relative;
    overflow: hidden;
}


    

.blog-preview {
    display: block !important;
    border-left-width: 600px !important;
    border-top-width: 754px !important;
    position: absolute;
    height: 0px;
    width: 12%;
    top: 0px;
    -webkit-transform: skew(0deg);
    -ms-transform: skew(0deg);
    transform: skew(0deg);
    border-top: 541px solid #17b4f0;
    border-left: 600px solid transparent;
    right: 0px;
    overflow: hidden;
    display: none;
}

.home-blog .title-right {
    position: absolute;
    top: 80px;
    right: 130px;
    font-size: 120px;
    color: rgba(246, 246, 246, 0.11);
    text-transform: uppercase;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    display: block;
}

.home-blog .entry-title a {
    color: #5e707d;
    font-size: 20px;
    line-height: 30px;
    text-decoration: none;
}
.home-blog .swiper-slide{
 padding: 20px 0px;
}
.home-blog .title a {
    color: #00aeef;
    font-size: 15px;
    text-decoration: none;
}
@media(max-width:768px){

    .home-blog .swiper-slide {
        width: 100% !important;
        transform: translateZ(0);
        backface-visibility: hidden;
    }
    .home-blog .swiper-slide img{
        width: 100%;
    }
    .home-blog  .title{
        padding: 10px 0px !important;
    }

}

