.faqs{
    background-image: url("../../img/faq_background.svg");
    padding: 80px 0px;
}

.faqs .faqs-items-wrap {
    padding: 40px 60px 65px;
}

 .faqs .container {
    width: 850px;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}

.faqs .faqs-items-wrap {
    width: 100%;
    display: block;
    background: #FFF;
    padding: 20px 30px 35px;
    -webkit-box-shadow: 0px 10px 35px rgba(94, 112, 125, 0.1);
    box-shadow: 0px 10px 35px rgba(94, 112, 125, 0.1);
    position: relative;
}

.faqs .faqs-items-wrap .faqs-item {
    position: relative;
    padding: 0;
    border-bottom: 2px dashed rgba(94, 112, 125, 0.2);
    font-family: "Open Sans", sans-serif;
}

.faqs .faqs-item h4 {
    font-size: 20px;
}


.faqs .faqs-items-wrap .faqs-item h4:after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 16px;
    height: 100%;
    background-image: url(https://cdn.acodez.in/wp-content/themes/acodez-theme/images/icon-plus.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 20px;
}

.faqs .faqs-items-wrap .faqs-item {
    position: relative;
    padding: 0;
    border-bottom: 2px dashed rgba(94, 112, 125, 0.2);
    font-family: "Open Sans", sans-serif;
}

.faqs .faqs-items-wrap .row-title-text {
    font-family: "Open Sans", sans-serif;
    margin: 0;
    position: relative;
    cursor: pointer;
    padding: 10px 30px 10px 0;
    margin: 0;
    font-weight: 500;
    line-height: 26px;
    color: #6d7593;
    
    font-size: 20px;
}

.faqs .row-content-text{
    font-weight: 400;
    line-height: 1.62;
    color: #686868;
    font-size: 16px;
}

@media(max-width:768px){
    .faqs .faqs-items-wrap .row-title-text {
        font-family: "Open Sans", sans-serif;
        margin: 0;
        position: relative;
        cursor: pointer;
        padding: 10px 30px 10px 0;
        margin: 0;
        font-weight: 500;
        line-height: 26px;
        color: #6d7593;
        font-size: 13px;
    }
}
@media (max-width: 480px) {
    .faqs .container {
        width: 365px !important;
    }
}