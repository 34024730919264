.swiper {
  width: 100%;
  height: 100%;
}

.slider1 .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider1 .swiper-slide img {
  display: block;
  width: 70%;
  height: 100%;
  object-fit: cover;
  webkit-filter: grayscale(1);
    filter: grayscale(1);
    opacity: 0.4;
}
.slider1 .swiper-slide img:hover{
  webkit-filter: grayscale(0);
  filter: grayscale(0);
  opacity: 1;
}
.swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
  display: none;
}

@media(max-width:768px){
  .slider1 .swiper-slide img {
    
    width: 50%;
   
}
}
@media(max-width:576px){
  .swiper-backface-hidden .swiper-slide {
    width: 180px !important;
    transform: translateZ(0);
    backface-visibility: hidden;
}
}
