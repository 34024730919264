h2 {
  display: inline-block;
  position: relative;
  font-size: 25px;
  color: #5e707d;
  padding-bottom: 10px;
  margin-bottom: 20px;
  font-family: "Montserrat", sans-serif;
}
h2:before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 55px;
  height: 3px;
  background: #00aeef;
  content: "";
}
.button-1{
  display: inline-block;
    height: 44px;
    line-height: 40px;
    padding: 0 20px;
    border-radius: 25px;
    color: #00aeef;
    font-size: 15px;
    border: 2px solid #00aeef;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    color: #fff;
    text-decoration: none;
}

.button-1:hover {
  background-color: #00aeef; 
  color: white;
}

.color-b{
  color: #00aeef;
}

@media(max-width:992px){
  .container{
 max-width: 920px !important;
  }
}

@media(max-width:768px){
  .container{
 max-width: 720px !important;
  }
  .faqs .container {
    width: 500px !important;}
}
@media(max-width:576px){
  
}





