


.product .button-1 {
color: #00aeef !important;
}
.product .header .ul-1 ul li a {
    color: #5e707d !important;
}

.product .caption {
    position: absolute;
   
    left: 0;
    width: 100%;
    top: 25%;
    text-align: left;
    z-index: 999;
}
.product .nav-bar-icon i {
    color: #5e707d !important;
}
@media(max-width:768px){
    .product .caption {
        position: relative;
       
        left: 0;
        width: 100%;
        top: 25%;
        text-align: left;
        z-index: 999;
    }

    .product-img1 .img {
        height: 300px;
        background-size: cover !important;
        background: url(https://cdn.acodez.in/wp-content/themes/acodez-theme/images/product-bannner.png) no-repeat left top;
    
    }
}