.achievement-main{
    padding: 120px 0px 0px;
    background-image: url('../../img/bg2.svg') ;
    background-repeat: no-repeat;
    background-position: center 355px;
}

.achievement-main .swiper-slide{
    width: 250px !important;
}
.achievement-outer a{
    display: block;
}
.achievement-outer  .counts {
  
    position: relative;
    font-size: 18px;
    line-height: 30px;
    color: #5e707d;
    
    text-align: left;
    display: inline-block;
    padding-left: 35px;
}
.achievement-outer .col-4 .counts {
    margin-bottom: 220px;
   
}
.achievement-outer .col-1 .counts {
    margin-bottom: 190px;
}
.achievement-outer .counts {
    margin-bottom: 128px;
}

.achievement .counts i {
    position: absolute;
    font-size: 23px;
    color: #6f7a82;
    top: 0;
    left: 0px;
}

.achievement-outer .counts h3 {
    font-size: 60px;
    line-height: 48px;
    color: #00aeef !important;
    margin-bottom: 10px;
    font-weight: 700;
}
.testimonial  .swiper-slide{
    width: 100% !important;
}
.testimonial {
    text-align: center;
}
.testimonial .pic {
    width: 104px;
    height: 104px;
    border: 5px solid #00aeef;
    border-radius: 50%;
    margin: 0 auto 35px auto;
    position: relative;
}
.testimonial .pic span {
    display: inline-block;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.testimonial span {
    font-size: 14px;
    color: #5e707d;
}
.testimonial img {
    border: 0;
    -ms-interpolation-mode: bicubic;
    vertical-align: middle;
    max-width: 100%;
}
.testimonial p {
    font-size: 18px;
    color: #6f7a82;
    margin-bottom: 20px;
    font-weight: 300;
    ine-height: 30px;
    width: 70%;
    margin: 0px auto;
}
.testimonial h4 {
    font-size: 20px;
    color: #00aeef;
}
.testimonial .swiper-horizontal > .swiper-pagination-bullets {
    display: block;
    margin-top: 50px;
  }

  @media(max-width:768px){
    .testimonial .swiper-slide {
        /* width: 650px !important; */
    }
    .achievement-outer .col-4 .counts {
        margin-bottom: 128px;
       
    }
    .achievement-outer .col-1 .counts {
        margin-bottom: 128px;
    }
  }
  @media(max-width:576px){
    .testimonial .swiper-slide {
        /* width: 523px !important; */
    }
  }

  @media (max-width: 480px) {
    .testimonial .swiper-slide {
        /* width: 365px !important; */
    }
}