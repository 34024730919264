.header{
    padding: 20px 0px;
    position: absolute;
    width: 100%;
}
.header-scroll{
    position: fixed;
    background-color: white;
    color: white;
    z-index: 99999;
}
.header-scroll .button-1{
    color: #00aeef !important;
  }
  .button-1:hover{
    color: white !important;
  }
.header-scroll .ul-1 ul li a{
    color: #5e707d !important;
}

.header .container{

}
.logo-img{
    width: 12%;
}
.logo-img img{
    width: 100%;
}

.header nav{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.header .ul-1 {
    display: flex;
    align-items: center;
}
.header .ul-1 ul{
    display: flex;
    list-style: none;


}
.header .ul-1 ul li{
margin: 0px 20px;
}
.header .ul-1 ul li a{
    text-decoration: none;
    color: white;
    position: relative;
    transition: 0.5s;
    padding-bottom: 10px;
}
.header .ul-1 ul li a:hover{
    color: #00aeef;
}
.header .ul-1 ul li a:hover::before{
    content: "";
        display: block;
        width: 100%;
        left: 0%;
        bottom:  10px;
        height: 1px;
        background: #00aeef;
        position: absolute;
        bottom: 2px;
        -webkit-transition: all 0.3s ease-out 0s;
        transition: all 0.3s ease-out 0s;
    
}


.nav-bar-icon{
    right: 40px;
    top: 29px;
    position: absolute;
}
.nav-bar-icon i{
    color: white;
    font-size: 30px;
}

.header .nav-top{
height: 0vh;
position: fixed;
background-color: white;
width: 100%;
top: 97px;
left: 0px;
z-index: 99;
transition: 0.5s;
overflow: hidden;


}
.header .nav-top-show{
    height:100vh;
}

.header .menu {
    padding: 50px 0;
    border-top: 1px solid #e5e5e5;
}

 

.nav-top nav{
display: flex;
justify-content: flex-start;
align-items:flex-start;
row-gap: 50px;
column-gap: 50px;
} 
.nav-top .nav-top-child2 {

    width: 50%;
}   
 
.nav-top nav>ul>li {
    position: static;
    border-bottom: 0;
}

.nav-top nav ul {
    list-style: none;
    list-style-image: none;
    margin: 0;
    padding: 0;
}
.nav-top nav>ul {
    width: 20%;
}


.nav-top-child1 ul{
    border-right: 1px solid #5e707d;
    padding-right: 20px !important;
}
.nav-top-child1 ul>li>a {
    font-size: 22px;
    padding: 0 0 15px 0;
    display: block;
    text-decoration: none;
    color: #5e707d;
    line-height: 33px;
    /* font-family: "Montserrat", sans-serif; */
    font-weight: 300;
}
.nav-top-child2{
    position: relative;
}
.nav-top-child2 p{
    
        font-size: 20px;
        text-indent: 40px;
        line-height: 38px;
        color: #919aa1;
        background: url(https://cdn.acodez.in/wp-content/themes/acodez-theme/images/quotes-icon.png) no-repeat 0 10px;
    
}
 .menu-txt {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0%;
    font-size: 170px;
    color: #f6f6f6;
    font-family: "Montserrat", sans-serif;
    top: 130%;
    font-weight: 700;
    
    z-index: 999;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.header-scroll .nav-bar-icon i {
    color: #5e707d !important;
    
}
.nav-bar-icon2{
    display: none !important;
}
@media(max-width:1200px){
    .header .ul-1 ul{
        display: none;
    }
    .header nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

@media(max-width:850px){
    .nav-bar-icon{
        right: 10px;
    }
}
@media(max-width:768px){
    .header .ul-1 .button-1{
        display: none;
    }
}