.webdesign-slider{
padding: 120px 0px;
}
.webdesign-slider .container{
    max-width: 1500px;
    display: flex;
    justify-content: space-around;
}
.webdesign-slider .slider-wrap{
    width: 75%;
    margin-left: -10%;
}
.webdesign-slider .slider-content{
width: 25%;
}
.webdesign-slider  .swiper-slide{
    width: 320px !important;
    position: relative;
}
.webdesign-slider .web-item  {
 width: 100% !important;
 margin-right: 0px !important;
 transition: 0.5s;
}
.webdesign-slider .swiper-slide img{
    width: 100%;
}

.webdesign-slider .outer-overlay {
    position: absolute;
    top: 50%;
    height: 100%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    padding: 130px 30px 30px 30px;
    -webkit-transition: all ease-in-out 0.8s;
    transition: all ease-in-out 0.8s;
    cursor: pointer;
    background-color: #00aeef;
   opacity: 0;
    transition: 0.5s !important;
}
.webdesign-slider  .swiper-slide:hover  .outer-overlay{
    opacity: 1;
   
}
.webdesign-slider .outer-overlay p {
    font-size: 14px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.8);
    font-weight: 400;
    direction: ltr;
    margin-bottom:15px;
}

.btn-1.white-cle {
    color: #fff !important;
    border: 2px solid #fff;
}
.btn-1 {
    display: inline-block;
    height: 44px;
    line-height: 40px;
    padding: 0 20px;
    border-radius: 25px;
    color: #00aeef;
    font-size: 15px;
    border: 2px solid #00aeef;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    text-decoration: none;
}