.play-ground {
    background: #fff;
    padding: 50px 15px;
    position: relative;
    z-index: 10;
    overflow: hidden;
}

.play-ground .flip-container {
    width: 440px;
    float: right;
    margin-left: 0;
    margin-top: 110px;
    height: 120px;
    margin: 0 auto;
    text-align: center;
    perspective: 1000px;
    margin-top: 70px;
   

}
.flipper{
    position: relative;
    transition: 1s;
    transform-style:preserve-3d ;
    background-color: red;
}
.play-ground .flip-container:hover .flipper, 
.play-ground .flip-container:focus .flipper {
    transform: rotateY(180deg);
    cursor: pointer;
 
}



.play-ground h3 {
    font-size: 110px;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    color: #00aeef;
    text-transform: uppercase;
}

.play-ground h5 {
    letter-spacing: 24px;
    padding-left: 24px;
    font-size: 24px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    letter-spacing: 15px;
    color: #00aeef;
    text-transform: uppercase;
}

.play-ground .front, .back {
 width: 100%;
 height: 100%;
 position: absolute;
 
   
    backface-visibility: hidden;
}
.play-ground .back {
transform: rotateY(180deg);
}

.play-ground .front {
    z-index: 2;
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    height: 120px;
    margin: 0 auto;
    text-align: center;
}
/* .play-ground {
    display: none;
} */
.play-ground h5:hover .back{
display: block;
}
@media(max-width:768px){
    .play-ground {
        height: 700px;
    }
    .play-ground .front {
      
        top: 380px;
        left: -109px;
       
    }
}

@media(max-width:576px){
    .play-ground h3 {
        font-size: 70px;
        font-weight: 400;
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        color: #00aeef;
        text-transform: uppercase;
    }
    .play-ground .front {
        top: 0px;
        left: -30px;
    }
    .play-ground .acodie-pic img{
        width: 100%;
    }
}

@media (max-width: 576px) {
    .play-ground .front {
        top: 0px;
        left: 57px;
    }}