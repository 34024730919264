.webdeveloper-slider{
    padding: 120px 0;
    background: rgb(248, 249, 251);
    padding: 50px 0;
    overflow: hidden;
}
.nav-tabs .nav-link.active {
    border: 0 !important;
}
.nav-tabs {
    border-bottom: 0px !important;
}
.nav-link:focus {
 border: 0px !important;
}
.webdevslider-tabs  .swiper-slide{
    width: 300px !important;
    position: relative;
    margin-right: 20px !important; 
    height: 300px !important;
}
.webdevslider-tabs .web-item  {
 width: 100% !important;
 margin-right: 0px !important;
 transition: 0.5s;
}


.webdevslider-tabs .swiper-slide img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.webdevslider-tabs .outer-overlay {
    position: absolute;
    top: 50%;
    height: 100%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    padding: 130px 30px 30px 30px;
    -webkit-transition: all ease-in-out 0.8s;
    transition: all ease-in-out 0.8s;
    cursor: pointer;
    background-color: #00aeef;
   opacity: 0;
    transition: 0.5s !important;
}
.webdevslider-tabs  .swiper-slide:hover  .outer-overlay{
    opacity: 1;
   
}
.webdevslider-tabs .outer-overlay p {
    font-size: 14px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.8);
    font-weight: 400;
    direction: ltr;
    margin-bottom:15px;
}

.btn-1.white-cle {
    color: #fff !important;
    border: 2px solid #fff;
}
.btn-1 {
    display: inline-block;
    height: 44px;
    line-height: 40px;
    padding: 0 20px;
    border-radius: 25px;
    color: #00aeef;
    font-size: 15px;
    border: 2px solid #00aeef;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    text-decoration: none;
}