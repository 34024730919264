.work{
    padding: 120px 0 0;
    position: relative;
    height: 950px;
}

.work-content {
    top: 385px;
    width: 430px;
    position: absolute;
    top: 430px;
    z-index: 99;
    padding: 50px 34px;
    margin: 0;
    -webkit-box-shadow: 0px 30px 62px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 30px 62px 0px rgba(0, 0, 0, 0.15);
    background: #00aeef;
}


.home-works .work-content h4 {
    font-size: 30px;
    margin-bottom: 6px;
    color: #fff;
}

.home-works .work-content p {
    color: #fff;
    margin-bottom: 0;
}

.work-slider {
    display: none;
}
.work .desk-work {
    margin-top: -65px;
    position: relative;
    float: right;
    width: 100%;
    min-height: 780px;
    margin-bottom: 80px;
}
.desk-work{
    position: relative;
        float: right;
        width: 100%;
        min-height: 780px;
        margin-bottom: 80px;
}
.desk-work .pic-1 {
    float: right;
    position: relative;
    right: 85px;
}

.desk-work .pic-2 {
    position: absolute;
    right: 35%;
    top: 120px;
}
.desk-work .pic-3 {
    position: absolute;
    right: 230px;
    top: 34%;
    -webkit-box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
}

.desk-work .pic-4 {
    position: absolute;
    right: 0;
    bottom: 70px;
    -webkit-box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
}

.desk-work .pic-5 {
    position: absolute;
    right: 26%;
    bottom: 0;
}

.desk-work .pic-6 {
    position: absolute;
    left: 35%;
    bottom: 30px;
}

.desk-work .work-details {
    position: absolute;
    bottom: 20px;
    left: 30px;
    font-size: 14px;
    color: #5e707d;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.desk-work .work-details h4 {
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
    color: #263238;
    margin-bottom: 0;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.desk-work .work-details a {
    font-size: 13px;
    line-height: 16px;
    font-weight: 600;
    color: #ED1D24;
    text-transform: uppercase;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}


@media(max-width:768px){
    .work-slider {
        display: block;
    }
    .work-slider  .swiper-slide {
        width: 303px !important;
        transform: translateZ(0);
        backface-visibility: hidden;
    }
    .work-content {
        top: 443px;
        width: 100%;
        padding: 30px 24px;
    
    }
    .home-works .work-content h4 {
        font-size: 25px;
        margin-bottom: 6px;
        color: #fff;
    }
    .desk-work{
        display: none;
    }

    .work {
        padding: 120px 0 0;
        position: relative;
        height: auto;
    }
}