.services .header .ul-1 ul li a {
    color: #5e707d !important;
}

.services .button-1 {
    color: #00aeef !important;
    }

    .services .nav-bar-icon i {
        color: #5e707d !important;
    }
.inner-page{
    width: 100%;
    overflow: hidden;
}

.inner-page .inner-banner {
    position: relative;
    z-index: -1;
}

.inner-banner img {
    display: block;
    width: 100%;
}
.col-md-offset-6 {
    margin-left: 50%;
    
}
.inner-banner .caption {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    top: 4%;
    text-align: left;
    z-index: 999;
}
.inner-banner h2 {
    font-size: 25px;
}
.inner-banner h2 {
    display: inline-block;
    position: relative;
    font-size: 25px;
    color: #5e707d;
    padding-bottom: 10px;
    margin-bottom: 20px;
    font-family: "Montserrat", sans-serif;
}

.inner-banner h2:before {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 55px;
    height: 3px;
    background: #00aeef;
    content: "";
}

.inner-banner .caption p {
    font-size: 20px;
    line-height: 35px;
    font-weight: 300;
}
.inner-banner .caption p a{
    text-decoration: none;
}

.title-4 {
    color: #5e707d;
    font-size: 20px;
    margin-bottom: 25px;
    font-weight: 500;
}

.service {
    min-height: 500px;
    padding: 80px 0;
    background: #f7f6f6;
}

.service-1 {
    background: url(https://cdn.acodez.in/wp-content/themes/acodez-theme/images/service-1.jpg) no-repeat right top;
    background-position: 5% top;
    background-size: cover !important;
    width: 100%;
}

.inner-body h3 {
    font-size: 47px;
    color: #6f7a82;
    margin-bottom: 15px;
    font-weight: 100;
}
.service p {
    font-size: 18px;
    line-height: 31px;
    font-weight: 100;
    margin-bottom: 20px;
}
.service h4 {
    font-size: 22px;
}
.list-s2 {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

.list-s2 li span {
    font-size: 16px;
    color: #5e707d;
    padding: 8px 0;
    position: relative;
    padding-left: 20px;
    display: inline-block;
}

.list-s2 li span:before {
    position: absolute;
    left: 0;
    top: 13px;
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 2px solid #00aeef;
    background: #fff;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.inner-page h3 a {
    color: #6f7a82;
    text-decoration: none;
}
.inner-page h3 {
    font-size: 47px;
}

.service-2 {
    background: url(https://cdn.acodez.in/wp-content/themes/acodez-theme/images/service-mobile-bg.jpg) no-repeat left top;
    background-position: 38% top;
}

.service-3 {
    background: url(https://cdn.acodez.in/wp-content/themes/acodez-theme/images/service-bg-3.jpg) no-repeat right top #f9f9f9;
    background-size: contain !important;
}
.list-03 {
    max-width: 470px;
    display: block;
}
.list-03 li {
    padding: 23px 18px;
    background: #fff;
    -webkit-box-shadow: 0px 30px 62px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 30px 62px 0px rgba(0, 0, 0, 0.15);
    margin-bottom: 20px;
    background: url(https://cdn.acodez.in/wp-content/themes/acodez-theme/images/icon_sprite.png) no-repeat 13px -508px #fff;
}
.list-03 li:nth-child(odd) {
    padding-left: 80px;
}
.list-03{
    list-style: none;
}
.list-03 li span {
    display: block;
    font-size: 18px;
    line-height: 40px;
    color: #5e707d;
}

.list-03 li:nth-child(2n) {
    text-align: right;
    padding-right: 100px;
}
.list-03 .dm-2 {
    background-position: 102% -580px;
}
.list-03 li:nth-child(odd) {
    padding-left: 80px;
}
.list-03 .dm-3 {
    background-position: 13px -673px;
}
.list-03 .dm-4 {
    background-position: 105% -754px;
}
.list-03 li:nth-child(2n) {
    text-align: right;
    padding-right: 100px;
}

.service-5 {
    background: url(https://cdn.acodez.in/wp-content/themes/acodez-theme/images/service-bg-5.jpg) no-repeat right top;
    background-position: 62% top;
}

.service-6 {
    background: url(https://cdn.acodez.in/wp-content/themes/acodez-theme/images/service-bg-6.jpg) no-repeat left top;
    background-position: 46% top;
}
.inner-page img {
    border: 0;
    -ms-interpolation-mode: bicubic;
    vertical-align: middle;
    max-width: 100%;
}

.service .pic {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.whats-new h3 {
    margin-top: 0;
    font-size: 35px !important;
    line-height: 40px;
    padding-right: 28px;
}



    .bottom-sec1 img {
        width: 100%;
    }


    .bottom-sec1 a.pic {
        display: block;
        overflow: hidden;
        color: #5e707d;
    }

    .service7 img{
  width: 50%;
    }
  


    @media(max-width:768px){
        .product .caption {
            position: relative;
           
            left: 0;
            width: 100%;
            top: 25%;
            text-align: left;
            z-index: 999;
        }
        .col-md-offset-6 {
            margin-left: 0%;
        }

        .title-4 {
            font-size: 20px;
            color: #5e707d;
            margin-bottom: 15px;
        }
        

.inner-banner img {
    display: none;
    width: 100%;
}

.ser-001 .img {
    height: 300px;
    background-size: cover !important;
    background: url(https://cdn.acodez.in/wp-content/themes/acodez-theme/images/service-banner.jpg) no-repeat left top;

}

.inner-page h3 {
    font-size: 30px;
        line-height: 35px;
}

.service-1{
    margin-top: 250px;

}
.title-4 {
    font-size: 20px;
    color: #5e707d;
    margin-bottom: 15px;
    display: none;
}
.service-2{
background: none;
}
.service-3{
    background: none;
    }
    .service-4{
        background: none;
        }
        .service-5{
            background: none;
            }
            .service-6{
                background: none;
                }
    }

    @media(max-width:580px){
        .service-1 {
            margin-top: 422px;
        }
    }