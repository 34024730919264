


.work-page .button-1 {
    color: #00aeef !important;
    }
    .work-page .header .ul-1 ul li a {
        color: #5e707d !important;
    }
    
    .work-page .caption {
        position: absolute;
        left: 0;
        width: 100%;
        top: 25%;
        text-align: left;
        z-index: 999;
    }
    .work-page .nav-bar-icon i {
        color: #5e707d !important;
    }

    .work-page-1{
        display: flex;
        justify-content: space-between;
        padding: 50px 0px;
        flex-wrap: wrap;
    }
    .work-page-1 .outer{
        box-shadow: 0px 15px 30px rgba(71, 84, 94, 0.1) !important;
        transition: 0.5s;
    }
    .work-page-1 .outer:hover{
        transform: scale(1.1);
    }
    .work-page-1  .websites{
  width: 23%;
    }
    .work-page-1  .item .trusted {
        padding: 20px;
    }

    .work-page-1 .item .trusted h4 {
        font-size: 18px;
        line-height: 30px;
        text-align: center;
        color: #47545E;
    }
    .work-page-1 .item .trusted p {
        
        font-size: 14px;
    color: #8fa0ad;
    line-height: 20px;
    text-align: center;
    }
    .imghvr-hinge-up{
        width: 100%;
        height: 300px;
    }
    .imghvr-hinge-up img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .work-page .main-title-h3{
        font-size: 47px;
        color: #6f7a82;
    margin-bottom: 15px;
    font-weight: 100;
    text-align: center;
    padding: 40px 0px;
    }
    @media(max-width:768px){
        .work-page .caption {
            position: relative;
           
            left: 0;
            width: 100%;
            top: 25%;
            text-align: left;
            z-index: 999;
        }
    
        .work-page .img {
            height: 300px;
            background-size: cover !important;
            background: url(https://cdn.acodez.in/wp-content/themes/acodez-theme/images/works-banner.jpg) no-repeat left top;
        
        }
        .work-page-1  .websites{
            width: 80%;
              }
              .work-page-1{
                display: flex;
                justify-content:center;
                align-content:center ;
                padding: 50px 0px;
                flex-wrap: wrap;
                row-gap: 20px;
            }
            .imghvr-hinge-up {
                width: 100%;
                height: 200px;
            }

            .work-page .main-title-h3{
                font-size: 30px;
                color: #6f7a82;
            margin-bottom: 15px;
            font-weight: 100;
            text-align: center;
            padding: 0px 0px;
            }
    }