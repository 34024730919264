.footer-sec-1 {
    padding: 100px 0 230px 0;
        text-align: center;
        position: relative;

}

.footer-sec-1 .bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: url(https://cdn.acodez.in/wp-content/themes/acodez-theme/images/footer-bg.jpg) no-repeat center top;
    z-index: -1;
    background-attachment: fixed;
    background-size: cover;
}
.footer-sec-1 h4{
    margin-bottom: 25px;
    font-size: 30px;
    font-family: "Montserrat", sans-serif;
    color: #fff;
}



    .footer-sec-1 p {
        margin-bottom: 25px;
        color: #889198;
        font-size: 15px;
        line-height: 30px;
    }
    .footer-sec-1 .btn-1{
        color: #fff !important;
    }