.about{
    background: url("../../img/vector-bg-1.webp") no-repeat right top;
    padding: 50px 0 0;
    
    position: relative;
    
}

.about .about-box1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 50px;
}
.about .about-box1-child1{
    width: 50%;
}
.about .about-box1-child1 h5{
    font-size: 23px;
    line-height: 50px;
    color: #2e3235;
    font-weight: 300;
    margin-bottom: 5px;
}
.about .about-box1-child1 p{
    font-size: 15px;
    line-height: 30px;
    color: #6f7a82;
    margin-bottom: 15px;

}

.about .about-box2{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
.about .about-card{
    width: 23%;
    border-radius: 0;
    background: #FFF;
    padding: 30px 30px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 360px;
    
    position: relative;
    -webkit-box-shadow: 0 16px 36px rgba(34, 82, 130, 0.06);
    box-shadow: 0 16px 36px rgba(34, 82, 130, 0.06);
    overflow: hidden;
    transition: 0.5s;
}
.about .about-card:hover{
    background:#00AEEF;
    color: white;
}
.about .about-card:hover h4{
    color: white;
}

.about .about-card:hover ul li{
    color: white;
}
.about .about-icon{
    margin: 0 0 20px;
}

.about .about-card h4{
    font-size: 18px;
    margin: 0 0 15px;
    font-weight: normal;
    color: #2F3336;
} 
.about .about-card ul{
    margin: 0;
    list-style: none;
    padding: 0;
    font-size: 14px;
}
.about .about-card ul li{
    padding: 5px 0 0 0px;
    color: #2e3235;
}
.about .about-icon{
    width: 30px;
}
.about .about-icon img{
    width: 100%;
}
.about .margin-t{
    margin-top: 140px;
}

.about .margin-b{
    margin-top: -140px;
}
@media(max-width:992px){
    .about .about-box2 {
        display: flex;
        flex-wrap: wrap;
        column-gap: 10px;
        row-gap: 50px;
    }
    .about .about-card {
        
        width: 48%;}
        .about .margin-t {
            margin-top: 0px;
        }
        .about .margin-b {
            margin-top: 0px;
        }
}

@media(max-width:768px){
    .about .about-card {
        
        width: 100%;}

        .about .about-box1-child1 h5 {
            font-size: 20px;
        line-height: 30px;
        }
}
@media(max-width:576px){
    .about .about-box1-child1{
 width: 100% ;
 margin: 10px 0px;
    }
    .none {
       display: none;
    }
}