.technologies .button-1 {
    color: #00aeef !important;
    }
    .technologies .header .ul-1 ul li a {
        color: #5e707d !important;
    }
    
    .technologies .caption {
        position: absolute;
        left: 0;
        width: 100%;
        top: 25%;
        text-align: left;
        z-index: 999;
    }
    .technologies .nav-bar-icon i {
        color: #5e707d !important;
    }

    .technologies-1{
        display: flex;
        justify-content: space-between;
        padding: 50px 0px;
        flex-wrap: wrap;
        row-gap: 20px;
    }
    .technologies-1 .outer{
        background: #f4f4f4;
        padding: 25px;
        -webkit-transition: all 0.3s ease-out 0s;
        transition: all 0.3s ease-out 0s;
        cursor: pointer;
    }
   
    .technologies-1 .outer:hover {
        background: #fff;
        -webkit-box-shadow: 0px 30px 62px 0px rgba(0, 0, 0, 0.15);
        box-shadow: 0px 30px 62px 0px rgba(0, 0, 0, 0.15);
    }
    .technologies-1  .websites{
  width: 17%;
    }
    .technologies-1 .icon {
        width: 100px;
        height: 100px;
        display: block;
        background: url(https://cdn.acodez.in/wp-content/themes/acodez-theme/images/technology.png) no-repeat 13px 0;
        margin: 0 auto 15px auto;
    }

    .technologies-1 h5 {
        font-size: 14px;
    text-align: center;
    font-weight: 100;
    }
    .technologies-1 .i-ecommerce {
        background-position: -96px 0;
    }

    .technologies-1 .i-cms {
        background-position: -202px 3px;
    }
    .technologies-1 .i-php {
        background-position: 13px -100px;
    }
    .technologies-1 .i-jquery {
        background-position: -95px -100px;
    }

    .technologies-1 .i-android {
        background-position: -202px -100px;
    }
    .technologies-1 .i-cakePHP {
        background-position: 13px -200px;
    }
    .technologies-1 .i-drupal {
        background-position: -95px -200px;
    }
    .technologies-1 .i-codeIgniter {
        background-position: -202px -200px;
    }
    .technologies-1 .i-dotNet {
        background-position: 13px -300px;
    }

    
    .technologies .main-title-h3{
        font-size: 47px;
        color: #6f7a82;
    margin-bottom: 15px;
    font-weight: 100;
    text-align: center;
    padding: 40px 0px;
    }
    @media(max-width:768px){
        .technologies .caption {
            position: relative;
           
            left: 0;
            width: 100%;
            top: 25%;
            text-align: left;
            z-index: 999;
        }
    
        .technologies .img {
            height: 300px;
            background-size: cover !important;
            background: url(https://cdn.acodez.in/wp-content/themes/acodez-theme/images/works-banner.jpg) no-repeat left top;
        
        }
        .technologies-1  .websites{
            width: 80%;
              }
              .technologies-1{
                display: flex;
                justify-content:center;
                align-content:center ;
                padding: 50px 0px;
                flex-wrap: wrap;
                row-gap: 20px;
            }

            .main-title-h3{
                font-size: 30px;
                color: #6f7a82;
            margin-bottom: 15px;
            font-weight: 100;
            text-align: center;
            padding: 0px 0px;
            }
        }