.contact .nav-bar-icon i {
    color: #5e707d !important;
}
.contact .button-1 {
    color: #00aeef !important;
    }
    .contact .header .ul-1 ul li a {
        color: #5e707d !important;
    }
    .contact .caption {
        position: absolute;
       
        left: 0;
        width: 100%;
        top: 25%;
        text-align: left;
        z-index: 999;
    }
    .conact-numbers{
        margin: 0px;
        padding: 0px;
    }
    .conact-numbers li {
        margin: 5px 0;
        list-style: none;
    }
    .conact-numbers li a {
        font-size: 22px;
        text-decoration: none;
    }
    .conact-numbers li a {
        font-size: 18px;
        color: #1d6cb9;
        font-weight: 500;
        font-family: "Montserrat", sans-serif;
        display: inline-block;
        background: url(https://cdn.acodez.in/wp-content/themes/acodez-theme/images/icon_sprite.png) no-repeat 0px -1230px;
        padding: 6px 0 6px 50px;
    }

    .conact-numbers li .us {
        background-position: 0 -1277px;
    }
    .conact-numbers li .uk {
        background-position: 0 -1318px;
    }
    .conact-numbers li .email {
        background-position: 0 -1368px;
    }

    .conact-numbers li .email {
        background-position: 0 -1368px;
    }



    .contact-top-sec1 {
        z-index: 9;
        position: relative;
    }

    .contact-top-sec1 .enquiry-box {
        margin-top: -100px;
        background: #fff;
        z-index: 9;
    }

    .contact-sec1 .enquiry-box {
        padding: 60px;
        margin: 80px 0 30px 0;
        -webkit-box-shadow: 0px 10px 62px 0px rgba(0, 0, 0, 0.15);
        box-shadow: 0px 10px 62px 0px rgba(0, 0, 0, 0.15);
    }
    .contact-sec1 .enquiry-box h3 {
        font-size: 26px;
        font-weight: bold;
        color: #6f7a82;
    margin-bottom: 15px;
    }

    .contact-sec1 .enquiry-box p {
        font-size: 19px;
        margin-bottom: 40px;
        color: #6f7a82;
    }
    .apply-now li {
        width: 100%;
        margin-bottom: 20px;
        position: relative;
    }
    .contact-sec1 .apply-now li {
        margin-bottom: 20px;
    }
    .apply-now{
        margin: 0px;
        padding: 0px;
        list-style: none;
    }
    .apply-now li>label {
        font-size: 17px;
        color: #5e707d;
        font-weight: 600;
        display: block;
        margin-bottom: 10px;
    }
    .contact-sec1 .apply-now label {
        margin-bottom: 0;
    }

    .contact-sec1 .apply-now .input-txt {
        padding: 0;
        border: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        border-bottom: 1px solid #eaeaea;
        width: 100%;
    height: 56px;
    line-height: 56px;
    font-size: 14px;
    }

    .submit-btn {
        width: 100%;
        border: none;
        min-height: 59px;
        border-radius: 3px;
        background: #ed4e4e;
        font-size: 16px;
        font-weight: 400;
        color: #fff;
        letter-spacing: 4px;
        font-family: "Montserrat", sans-serif;
    }
    .contact-sec1 textarea {
        height: 245px;
        border: 1px solid #eaeaea;
    padding: 10px;
    background: #fcfcfc;
    box-shadow: none;
    font-size: 14px;
    outline: none;
    color: #454d54;
    min-height: 100px;
    line-height: 18px;
    width: 100%;
    border-radius: 3px;
    }
    .contact-sec1 .enquiry-box p {
        font-size: 19px;
        margin-bottom: 40px;
        font-size: 16px;
    }

    .contact-location h1 {
        font-size: 120px;
        line-height: 120px;
        margin-bottom: 20px;
        font-family: "Montserrat", sans-serif;
    font-weight: 600;
    color: #6f7a82;
    opacity: 0.1;
    text-align: center;
    }
    .acodez-follow-us {
        padding-top: 40px;
        text-align: center;
    }
    .acodez-follow-us h4 {
        font-size: 35px;
        margin-bottom: 25px;
        font-family: "Montserrat", sans-serif;
    margin-bottom: 10px;
    color: #92a0a9;
    font-weight: 700;
    }
    .social-links {
        margin-bottom: 15px;
    }
@media(max-width:768px){
    .contact-img .img {
        height: 300px;
        background-size: cover !important;
        background: url(https://cdn.acodez.in/wp-content/themes/acodez-theme/images/contact-banner.jpg) no-repeat left top;
    
    }
    .contact .caption {
        position: relative;
       
        left: 0;
        width: 100%;
        top: 25%;
        text-align: left;
        z-index: 999;
    }


}