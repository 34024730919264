.won{
    background: rgba(242, 245, 248, 0.66);
    padding: 120px 0;
}
.won-box1{
    display: flex;

}

.won-box1-child1{
    width: 50%;
}
.won-box1-child1 p{
    font-weight: normal;
    color: #6f7a82;
    margin-bottom: 25px;    margin-bottom: 25px;
    font-size: 15px;
        line-height: 30px;
}

.won-box1-child2{
   
        background: url(https://cdn.acodez.in/wp-content/themes/acodez-theme/images/new/bg.svg) no-repeat center left;
        background-size: contain;
        padding-top: 80px;
        background-position: center right;


}

.won-box1-child2 .box-2 {
    width: 373px;
    margin-top: 0;
    display: block;
    float: left;
    padding: 30px;
    margin: 0;
    box-shadow: 0px 10px 36px 0px rgba(89, 106, 117, 0.09);
    border-radius: 0;
    padding: 24px;
    background: #fff;
    position: relative;

}
.won-box1-child2 h4{
    font-size: 18px;
    color: rgb(104, 104, 104);
    margin-bottom: 30px;
    text-align: left;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;

}
.won-box1-child2 h4 span {
    font-size: 26px;
    font-weight: 600;
    margin-right: 6px;
    position: relative;
}
.won-box1-child2  ul {
    padding: 0;
    margin: 0;
    list-style: none;
}
.won-box1-child2 a{
    text-decoration: none;
}

.won-box1-child2 .box-2 li {
    margin-bottom: 20px;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
}
.won-box1-child2 .box-2 li .pic{
 width: 25%;
}
.won-box1-child2 .box-2 li img{
  
        border: 0;
        -ms-interpolation-mode: bicubic;
        vertical-align: middle;
        max-width: 100%;
    
}
.won-box1-child2 .box-2 li .cont-area {
    padding-left: 15px;
}

.won-box1-child2  .list-02 h6 {
    font-size: 16px;
    margin: 0 0 3px;
    color: rgb(47, 51, 54);
    line-height: 16px;
    font-weight: 500;
    text-decoration: none;
}
.won-box1-child2  .list-02 p {
    font-size: 13px;
    color: rgb(104, 104, 104);
    line-height: 1.8em;
    font-weight: 500;
    margin-bottom: 25px;
}
.won-box1-child2 .won-flex{
 display: flex;
}
.won-box1-child2 .accolades-heading {
    display: block;
    float: none;
    clear: both;
    color: rgba(34, 82, 130, 0.1);
    text-transform: uppercase;
    font-weight: 700;
    font-size: 58px;
    font-family: "Montserrat", sans-serif;
    opacity: 0.55;
    margin: 20px 0 0 38px;
}
.new-accolades{
    position: relative;
  
}
.new-accolades ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.new-accolades ul li {
    position: absolute;
    bottom: 50px;
    right: 0;
    z-index: 1;
    display: block;
    margin: 10px;
    width: auto;
}
.new-accolades ul li:first-child {
    right: 140px;
    bottom: 450px;
}

.new-accolades ul li:nth-child(2) {
    bottom: 390px;
    right: 0;
}

.new-accolades ul li:nth-child(3) {
    bottom: 290px;
    right: 110px;
}

.new-accolades ul li:nth-child(4) {
    bottom: 270px;
}
.new-accolades ul li:nth-child(5) {
    bottom: 160px;
    right: 55px;
}

.new-accolades ul li:nth-child(6) {
    bottom: 30px;
    right: 130px;
}

.new-accolades ul li img {
    -webkit-box-shadow: 0 5px 26px rgba(34, 82, 130, 0.08);
    box-shadow: 0 5px 26px rgba(34, 82, 130, 0.08);
    display: block;
    border-radius: 50%;
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    width: auto;
}

@media(max-width:992px){
    .new-accolades ul li:first-child {
        right: 60px;
        bottom: 450px;
    }

    .new-accolades ul li:nth-child(2) {
        bottom: 360px;
        right: 0;
    }

    .new-accolades ul li:nth-child(3) {
        bottom: 250px;
        right: 10px;
    }
    .new-accolades ul li:nth-child(4) {
        bottom: 200px;
    }
    .new-accolades ul li:nth-child(5) {
        bottom: 125px;
        right: 55px;
    }
    .new-accolades ul li:nth-child(6) {
        bottom: -15px;
        right: 88px;
    }
}

@media(max-width:768px){

    .won-box1 {
        display: flex;
        flex-direction: column;
    }
    .won-box1-child1 {
        width: 100%;
    }
    .won-box1-child2 .box-2 {
        width: 75%;}
        .webdesign-slider .slider-content {
            width: 100% !important;
        }

        .webdesign-slider .container {
            
            flex-direction: column-reverse;
        }

        .webdesign-slider .slider-wrap {
            width: 100% !important;
            margin-left: 0% !important;
        }
        .won-img img{
            width: 100%;
        }
}
@media(max-width:580px){
    .won-box1-child2 .accolades-heading {
        margin: 20px 0 0 0px;
        font-size: 48px;
    }
}