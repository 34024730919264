.hero{
   
        width: 100%;
        background: url("../../img/main-bg-4.webp") no-repeat 27% top;
        background-size: cover;
        height: 100vh;
        overflow: hidden;
    
}
.hero .text1{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.hero .text-child{
    width: 500px;
    overflow: hidden;
}
.hero .text-child p{
 color: white;
 font-size: 40px;
 line-height: 50px;
 text-align: center;
}
#hero-text1,#hero-text3,#hero-text2{
    position: relative;
    
    margin-bottom: 0px !important;
    font-size: 40px;
    letter-spacing: 5px;
    padding: 10px;
}


#hero-text1 span{
    position: absolute;
    width: 0px;
    height: 100%;
    left: -200px;
    display: block;
    margin-right: 0px;
    background-color: #00AEEF;
    animation: text1 1.1s  linear infinite;
   
}
@keyframes text1{
    0%{
        left: -200px;
        width: 0%;
        
    }
   
    100%{
        left: 500px;
        width: 600px;
        
        
    }
}



#hero-text2 span{
    position: absolute;
    width: 0px;
    height: 100%;
    left: -200px;
    display: block;
    margin-right: 0px;
    background-color: #00AEEF;
    animation: text2 1.1s  linear infinite;
   
}
@keyframes text2{
    0%{
        left: -200px;
        width: 0%;
        
    }
   20%{
    left: -200px;
        width: 0%;
   }
    100%{
        left: 500px;
        width: 600px;
        
        
    }
}

#hero-text3 span{
    position: absolute;
    width: 0px;
    height: 100%;
    left: -200px;
    display: block;
    margin-right: 0px;
    background-color: #00AEEF;
    animation: text3 1.1s  linear infinite;
   
}
@keyframes text3{
    0%{
        left: -200px;
        width: 0%;
        
    }
    40%{
        left: -200px;
        width: 0%;
    }
   
    100%{
        left: 500px;
        width: 600px;
        
        
    }
}

@media(max-width:768px){
    .hero .text-child {
        width: 393px;
        overflow: hidden;
    }
}