.blog .header .ul-1 ul li a {
    color: #5e707d !important;
}
.blog .button-1 {
    color: #00aeef !important;
    }
.blog .caption {
    position: absolute;
    left: 0;
    width: 100%;
    top: 30%;
    text-align: left;
    z-index: 999;
}
.blog .nav-bar-icon i {
    color: #5e707d !important;
}
.blog article {
    margin-bottom: 78px;
}

.blog .aside {
    float: right;
}

.blog img {
    width: 100%;
    height: auto;
}

.blog article.odd .blog-cnt {
    float: right;
}

.blog .first-blog .blog-cnt {
    margin-top: 40px;
}

.even .row{

    flex-direction: row-reverse;
}




    .blog .blog-cnt {
        background: #fff;
        padding: 45px 20px 20px 20px;
        -webkit-box-shadow: 0px 30px 62px 0px rgba(0, 0, 0, 0.15);
        box-shadow: 0px 30px 62px 0px rgba(0, 0, 0, 0.15);
        position: relative;
        clear: both;
        width: 540px;
        margin: 200px 0 0;
        padding: 70px 50px 50px;
    }

    .glog-date {
        width: 85px;
        height: 85px;
        border-radius: 50%;
        background: #00aeef;
        font-size: 14px;
        color: #fff;
        text-align: center;
        font-weight: 600;
        padding-top: 21px;
        position: absolute;
        top: -45px;
        left: 23px;
        -webkit-transition: all 0.3s ease-out 0s;
        transition: all 0.3s ease-out 0s;
        text-transform: uppercase;
    }

    .glog-date span {
        display: block;
        font-size: 18px;
    }
    .blog h4 {
        font-size: 22px;
        line-height: 31px;
        color: #3b474b;
    font-weight: 700;
    margin-bottom: 15px;
    }

    .blog .blog-cnt a {
        color: #3b474b;
        text-decoration: none;
    }
    .blog p {
        margin-bottom: 13px;
        font-size: 15px;
        line-height: 30px;
        color: #6f7a82;
    }

    .blog span.post-detail {
        font-size: 12px;
        color: #adb6bd;
        border-top: 1px solid #e5e5e5;
        display: block;
        padding-top: 10px;
    }

    .blog article.even .glog-pic {
        float: right;
    }
    .blog article.even .blog-cnt {
        float: left;
    }
@media(max-width:768px){
    .blog-img .img {
        height: 300px;
        background-size: cover !important;
        background: url(https://cdn.acodez.in/wp-content/themes/acodez-theme/images/blog-banner.jpg) no-repeat left top;
    
    }
    .blog .caption {
        position: relative;
       
        left: 0;
        width: 100%;
        top: 25%;
        text-align: left;
        z-index: 999;
    }
    .blog .blog-cnt {
        background: #fff;
        padding: 45px 20px 20px 20px;
        box-shadow: 0px 30px 62px 0px rgba(0, 0, 0, 0.15);
        position: relative;
        clear: both;
        width: auto;
        margin: 200px 0 0;
        padding: 70px 50px 50px;
    }


}



